<template>
  <div
    class="visit-list-item"
    :style="`height: ${isSub ? '190px' : '130px'}`"
    @click="
      isSub
        ? $router.push(`insight-create?id=${item.physician_msl_insight_id}`)
        : $router.push(
            `visit-create?id=${item.physician_mr_call_id}&isCoVisit=${item.is_co_call}`,
          )
    "
  >
    <div class="modify" :style="`background: ${color}`"></div>
    <div style="width: 99%">
      <div class="item-main">
        <div class="custom-item-left">
          <img src="./../../../assets/lundbeck/portrait.png" class="avatar" />
          <div class="tags">
            <span v-if="item.is_diner" class="tag">餐费</span>
          </div>
        </div>
        <div class="custom-item-right">
          <div class="header clearfix">
            <div class="name" style="flex: 1">
              <span class="name-w" v-if="item.physician.physician_name">{{ item.physician.physician_name }}</span>
            </div>
          </div>
          <p class="department">
            {{ item.physician.department_name }}
            {{ item.physician.professional_title }}
          </p>
          <div class="address">
            <div class="hospital">
              <span class="e-ellipsis-l1">
                {{ item.physician.institution_name }}
              </span>
            </div>
          </div>
          <div class="address">
            <div class="hospital">
              <span v-if="item.call_time" class="e-ellipsis-l1">
                {{ item.call_time.substring(0, 10) }}
              </span>
              <span v-if="item.insight_time" class="e-ellipsis-l1">
                {{ item.insight_time.substring(0, 10) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isSub"
        class="item-sub"
        style="
          padding-right: 10px;
          height: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
        "
      >
        <div style="margin-bottom: 10px; color: #8bc3ee">
          {{ item.insight_question?.question }}
        </div>
        <div class="max-line-2">
          {{ item.insight_content }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VisitListItem',
  props: ['color', 'isSub', 'item'],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>
<style scoped lang="scss">
@import '@/styles/var.scss';
.visit-list-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 20px;
  .modify {
    width: 1%;
    height: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .item-main {
    margin-left: 30px;
    display: flex;
    align-items: center;
    .custom-item-left {
      text-align: center;
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .tags {
        font-size: 10px;
        margin-top: 5px;
        .tag {
          border: 1px solid #ffbe47;
          border-radius: 20px;
          padding: 1px 4px;
          color: #ffbe47;
        }
      }
    }
    .custom-item-right {
      flex: 1;
      font-size: 12px;
      text-align: left;
      padding-left: 30px;
      @include suggest-detail-header();
      .count {
        // float: right;
        font-size: 19px;
        color: #b2b2b2;
        .count-color {
          color: #3caa45;
          min-width: 32px;
        }
      }
      .department {
        padding: 6px 0 4px 0;
      }
      .address {
        color: #b2b2b2;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        .hospital {
          flex: 1;
          min-width: 0;
          width: 0;
          line-height: 20px;
          span {
            display: block;
          }
        }
        .cube-btn {
          // width: 84px;
          padding: 4px 8px;
          font-size: 11px;
          border-radius: 20px;
        }
      }
    }
  }
  .item-sub {
    font-size: 12px;
    text-align: left;
    margin-left: 25px;
    margin-top: 10px;
  }
}
</style>
